/*
 * 업무구분: 단체일괄동의승인
 * 화 면 명: MSPAP508M
 * 화면설명: 단체일괄동의승인
 * 접근권한: 지점장
 * 작 성 일: 2022.06.28
 * 작 성 자: 조한결
 */
<template>
  <ur-page-container :show-title="true" title="단체일괄동의승인" type="subpage" action-type="search" @action-search-click="fn_Open514Pop"  @on-scroll-bottom="fn_MoreList(lv_SearchData)" @on-header-left-click="fn_BackBtnClicked">
      <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum">
        <mo-list-item>
          <mo-checkbox v-model="isOneOrMoreCheck" @input="checkAll" :disabled="lv_gBndlCnsntAprv.length === 0"></mo-checkbox>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum" v-if="!isEmpty">{{checkCount > 0 ? checkCount + '명 선택' : '조회 ' + lv_gBndlCnsntAprvCnt + '건' }}</span>
              <span class="sum" v-else>총 {{lv_gBndlCnsntAprv.length}}건</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      </template>
    <ur-box-container alignV="start" class="ns-list-area">
    <!-- NoData 영역 ::start  -->
     <ur-box-container v-if="lv_gBndlCnsntAprv.length === 0" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list no-data">
      <mo-list-item>
        <div class="list-item__contents">
          <div class="list-item__contents__info">
            <span v-if="lv_NodataFlag === 1">상세조회 조건을 설정해 주세요.</span>
            <span v-else>데이터가 없습니다.</span>
          </div>
        </div>
      </mo-list-item>
     </ur-box-container>
    <!-- NoData 영역 ::end  -->

      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list3">
        <mo-list :list-data="lv_gBndlCnsntAprv"> <!-- 최초 10건 조회 후 스크롤 끝에 도달했을 때 다음조회 -->
          <!-- <mo-list :list-data="lv_gBndlCnsntAprv" @on-scroll="onScroll" ref="scroll" @loading-data="fn_MoreList" :is-loading="isLoading"> -->
          <template #list-item="{item}">
            <mo-list-item>
              <mo-checkbox v-model="item.checked">
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span class="name">{{item.gCustNm}}</span>
                    <!-- <mo-badge class="badge-sample-badge blue" text="item.data2" shape="status">{{item.data2}}</mo-badge> -->

                    <!-- <mo-badge class="badge-sample-badge gray" text="양식미제출" shape="status">양식미제출</mo-badge> -->
                  </div>
                  <div class="list-item__contents__info">
                    <span class="info">사업자등록번호</span>
                    <span class="info">{{ fn_PlaceHolder(item.bmanNo,1) }}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <span class="info">단체코드</span>
                    <span class="info">{{ item.gCustId }}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <span class="info">법인등록번호</span>
                    <span class="info">{{ fn_PlaceHolder(item.corpNo,2) }}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <span class="info"><b>{{item.aplrNm}}</b></span>
                    <span class="info">{{item.aplrEno}}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <span class="info">신청일</span>
                    <span class="info">{{fn_DatePrint(item.aplDtm)}}</span>
                  </div>
                </div>
              </mo-checkbox>
            </mo-list-item>
          </template>
        </mo-list>
      </ur-box-container>
        <div v-show="isLoading" class="scroll_progress">
          <mo-indeterminate-progress size="small"/>
        </div>
        <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-btn-relative" v-if="checkCount > 0">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="!checkCount"  @click="fn_ConfirmAprvProc">승인</mo-button>
          </div>
        </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import MSPAP514P from '@/ui/ap/MSPAP514P'
  import moment from 'moment';
  import Msg from '@/systems/webkit/msg/msg'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP508M",
    screenId: "MSPAP508M",
    components: {},
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
      console.log('this.lv_UserInfo', this.lv_UserInfo.srvcMngScCd, this.lv_UserInfo.isBMngr)
      this.fn_SearchUserSrvcMngScCd()
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
    },
    mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPAP508M')
    },
    beforeDestroy() {
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
      stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: ''}], // NextKey 방식의 페이징에서 다음 페이지의 첫번째 항목의 Key값 저장(http.js에 선언된 변수명과 동일한 이름 사용)
      stndKeyVal: '',
      pageRowCnt: '10',
      isLoading: false,
      lv_IsFirst: true,
      lv_NodataFlag: 1,
      isEmpty: false,
      isScrolled: false, // 스크롤 관련
      lv_Message: '선택요청건을 승인 처리하시겠습니까 ?',
      lv_gBndlCnsntAprvCnt: 0, // 조회 건 수
      lv_gBndlCnsntAprv: [], // 조회 결과 데이타
      lv_gBndlCnsntAprvParam: {inqrStrAplDtm: '', inqrEndAplDtm: '', inqrCnsltNo: ''}, // 조회 Param
      isOneOrMoreCheck: false, //
      lv_ConfirmAprlProc: false, // 승인처리
      lv_popup514: {}, // MSPAP514P 상세조회 팝업
      lv_AlertPop: {},
      lv_SearchData: {},
      lv_UserInfo: '',
      lv_FofCnslt: {key: '0', label: '전체'}, // 화면 최초진입 시 조회를 위한 컨설턴트 초기값
      lv_IsRole: false, // 화면 최초진입 시 조회를 위한
      lv_SearchParam: {inqrStrAplDtm: '', inqrEndAplDtm: '', inqrCnsltNo: ''}, // 화면 최초진입 시 조회를 위한 조회 Param
      indexOfAMGA: 0,
      indexOfFS: 0,
      srchPopupShowYn: false,
      lv_AlertPopup: {}
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
    checkCount () {
      return this.lv_gBndlCnsntAprv.filter(item => item.checked).length
    }
    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
  watch: {
    checkCount () {
      if (this.lv_gBndlCnsntAprv.length === 0) {
        this.isOneOrMoreCheck = false
      } else {
        if (this.checkCount === this.lv_gBndlCnsntAprv.length) {
          this.isOneOrMoreCheck = true
        } else {
          this.isOneOrMoreCheck = false
        }
      }
    }
  },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
    /*********************************************************
    * Function명: checkAll
    * 설명: 단체일괄동의승인 전체선택 여부
    * Params: N/A
    * Return: N/A
    *********************************************************/
    checkAll () {
      for (let n in this.lv_gBndlCnsntAprv) {
        if (this.isOneOrMoreCheck) {
          this.lv_gBndlCnsntAprv[n].checked = true
        } else {
          this.lv_gBndlCnsntAprv[n].checked = false
        }
      }
    },
    /*********************************************************
    * Function명: fn_SelListReqBndlCnsnt
    * 설명: 단체일괄동의 승인요청 목록 조회 (지점기준 전체건 조회)
    * Params: N/A
    * Return: GCCustBndlCnsntSVO
    *********************************************************/
    fn_SelListReqBndlCnsnt (pData) {
      var lv_Vm = this
      console.log('pData : ', pData)
      console.log(lv_Vm.lv_UserInfo)
      if (pData === 'init') {
        const _trnstId = 'txTSSGC60S3'
        const _auth = 'S'
        this.isLoading = true
        let params = lv_Vm.lv_SearchParam
        console.log('lv_Vm.lv_SearchParam', lv_Vm.lv_SearchParam)
        params.inqrCnsltNo = lv_Vm.lv_FofCnslt.key // 컨설턴트
        params.onpstFofOrgNo = lv_Vm.lv_UserInfo.onpstFofOrgNo // 지점 부서코드
        this.post(lv_Vm, params, _trnstId, _auth)
          .then(function (response) {
            if (lv_Vm.$commonUtil.isSuccess(response)) {
              if ( response.body.totalCnt > 0 ) {
                let rtnList = response.body.gCCustBndlCnsntDtlSVO
                // let rtnListTmp = []
                if (_.isEmpty(rtnList) && lv_Vm.lv_SearchData.length === 0) {
                  lv_Vm.lv_NodataFlag = 2 // 검색결과가 존재하지 않습니다.
                }
                lv_Vm.fn_DupCheckGcustId( rtnList)
                lv_Vm.detailAreaOpen = false
              } else {
                lv_Vm.lv_NodataFlag = 2
              }
            } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            lv_Vm.fn_BottomAlert(response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
            // 더보기 키값
            lv_Vm.stndKeyList = response.trnstComm.stndKeyList
            if (lv_Vm.stndKeyList === null || lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === '') {
              lv_Vm.stndKeyVal = 'end' // 다음페이지 데이터 존재안함(마지막 페이지)
            } else {
              lv_Vm.stndKeyVal = 'more' // 다음페이지 데이터 존재함
            }
            lv_Vm.isLoading = false
            lv_Vm.lv_gBndlCnsntAprvCnt = lv_Vm.lv_gBndlCnsntAprv.length
          })
          .catch(function (error) {
            window.vue.error(error)
            // 시스템적인 오류가 발생하면, 일반적인 에러 메세지를 confirm으로 표시
            lv_Vm.fn_BottomAlert(error.message )
          })
      } else {
        const _trnstId = 'txTSSGC60S3'
        const _auth = 'S'
        this.isLoading = true
        let params = lv_Vm.lv_gBndlCnsntAprvParam
        console.log('params', params)
        if (Object.keys(lv_Vm.indexOfAMGA).length !== 0 && lv_Vm.indexOfAMGA > -1) { // GA,AFC인 경우 조회 대리점의 부서코드
          params.onpstFofOrgNo = pData.fofOrg.key
        } else {
          if (Object.keys(lv_Vm.indexOfFS).length !== 0 && lv_Vm.indexOfFS > -1) { // 삼성금융서비스인 경우
            params.onpstFofOrgNo = pData.fofOrg.key
          } else {
            params.onpstFofOrgNo = pData.fofOrg.key // 지점 부서코드
          }
        }
        params.inqrStrAplDtm = pData.selectStrDate
        params.inqrEndAplDtm = pData.selectEndDate
        params.inqrCnsltNo = pData.cnslt.key // 컨설턴트
        this.post(lv_Vm, params, _trnstId, _auth)
          .then(function (response) {
            if (lv_Vm.$commonUtil.isSuccess(response)) {
              console.log('response.body :: ', response.body)
              if ( response.body.totalCnt > 0 ) {
                let rtnList = response.body.gCCustBndlCnsntDtlSVO
                // let rtnListTmp = []
                if (_.isEmpty(rtnList) && lv_Vm.lv_gBndlCnsntAprv.length === 0) {
                  lv_Vm.lv_NodataFlag = 2 // 검색결과가 존재하지 않습니다.
                }
                lv_Vm.fn_DupCheckGcustId( rtnList)
              } else {
                lv_Vm.lv_NodataFlag = 2
              }
            } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              lv_Vm.fn_BottomAlert(response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
            // 더보기 키값
            lv_Vm.stndKeyList = response.trnstComm.stndKeyList
            if (lv_Vm.stndKeyList === null || lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === '') {
              lv_Vm.stndKeyVal = 'end' // 다음페이지 데이터 존재안함(마지막 페이지)
            } else {
              lv_Vm.stndKeyVal = 'more' // 다음페이지 데이터 존재함
            }
            lv_Vm.isLoading = false
            lv_Vm.lv_gBndlCnsntAprvCnt = lv_Vm.lv_gBndlCnsntAprv.length
          })
          .catch(function (error) {
            window.vue.error(error)
            // 시스템적인 오류가 발생하면, 일반적인 에러 메세지를 confirm으로 표시
            lv_Vm.fn_BottomAlert(error.message )
          })
      }
    },
    /******************************************************************************
      * Function명 : fn_DupCheckGcustId
      * 설명       : 중복제거

    ******************************************************************************/
    fn_DupCheckGcustId (rtnList) {
      var lv_Vm = this

      let rtnListTmp = rtnList.filter(item => item.infoPrcusCnsntStatCd === '01' ) // 승인신청 01 건 추츨
      let cnt = 0
      for (let i in rtnListTmp ) { // 중복제거
        cnt = 0
        if (i === 0) {
          rtnListTmp[i].checked = false
          lv_Vm.lv_gBndlCnsntAprv.push(rtnListTmp[i])
        } else {
          for (let j in lv_Vm.lv_gBndlCnsntAprv ) {
            if ( rtnListTmp[i].gCustId === lv_Vm.lv_gBndlCnsntAprv[j].gCustId) { // 단체코드
              if (rtnListTmp[i].cnsltNo === lv_Vm.lv_gBndlCnsntAprv[j].cnsltNo ) { // 컨설턴트
                cnt++
                break
              }
            }
          }
          if (cnt === 0) {
            rtnListTmp[i].checked = false
            lv_Vm.lv_gBndlCnsntAprv.push(rtnListTmp[i])
          }
        }
      }
    },
    /******************************************************************************
      * Function명 : fn_MoreList
      * 설명       : 페이징 조회
      * this.stndKeyVal -> more then 조회.
      *                 -> end then 조회마지막
    ******************************************************************************/
    fn_MoreList () {
      if (this.stndKeyVal === 'end') return
      this.stndKeyVal = '' // 더보기 최초조회
      if (Object.keys(this.lv_SearchData).length === 0) {
        this.fn_SelListReqBndlCnsnt('init')
      } else {
        this.fn_SelListReqBndlCnsnt(this.lv_SearchData)
      }
    },
    /*********************************************************
    * Function명: fn_ConfirmAprvProc
    * 설명: 단체일괄동의 승인처리전 최종 확인
    * Params: N/A
    * Return: N/A
    *********************************************************/
    fn_ConfirmAprvProc () {
      if ( this.checkCount >= 21 ) { // 최대 20개만
        this.fn_BottomAlert('최대 20개까지만 동시 승인처리가능 합니다.')
        return
      } else {
        this.lv_ConfirmAprlProc = true
        this.fn_BottomConfirm(this.lv_Message)
      }
    },
    /*********************************************************
    * Function명: fn_ConfirmAprvProcResult
    * 설명: 단체일괄동의 승인처리전 최종 확인 결과에 따른 처리
    * Params: N/A
    * Return: N/A
    *********************************************************/
    fn_ConfirmAprvProcResult (bOk) {
      if ( bOk ) {
        this.fn_ReqBndlCnstnt()
      }
    },
    /*********************************************************
    * Function명: fn_ReqBndlCnstnt
    * 설명: 단체일괄동의 승인처리
    * Params: N/A
    * Return: N/A
    *********************************************************/
    fn_ReqBndlCnstnt () {
      var lv_Vm = this
      const _trnstId = 'txTSSGC60P2'
      const _auth = 'S'
      let params = {gCCustBndlCnsntDtlSVO: this.lv_gBndlCnsntAprv.filter(item => item.checked)}

      this.post(lv_Vm, params, _trnstId, _auth)
        .then(function (response) {
          if (lv_Vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
              lv_Vm.fn_BottomAlert(response.msgComm.msgDesc)
              lv_Vm.fn_SearchList()
            } else {
              lv_Vm.fn_BottomAlert(response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          } else {
            lv_Vm.fn_BottomAlert(response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
          // 시스템적인 오류가 발생하면, 일반적인 에러 메세지를 confirm으로 표시
          lv_Vm.fn_BottomAlert(error.message )
        })
    },
    /*********************************************************
    * Function명: fn_PlaceHolder
    * 설명: 사업자등록번호, 법인번호 출력 포맷 ( null 체크생략 null 경우 승인요청불가 )
    * Params: N/A
    * Return: N/A
    *********************************************************/
    fn_PlaceHolder ( pNo, pType ) {
      if ( pType === 1 ) { // 사업자등록번호
        return pNo.replace(/([0-9]{3})([0-9]{2})([0-9]{5})/, '$1-$2-$3')
      } else if ( pType === 2 ) { // 법인번호
        return pNo.replace(/([0-9]{6})([0-9]{7})/, '$1-$2')
      } else {
        return pNo
      }
    },
    /******************************************************************************
     * Function명  : fn_DatePrint
     * 설명        : 일시를 날짜형식으로 출력
     * YYYYMMDDHHMMSS -> YYYY-MM-DD
     ******************************************************************************/
    fn_DatePrint ( pParam ) {
      let t_Return = pParam
      if ( !_.isEmpty(t_Return) && t_Return.length > 8 ) {
        let t_Str = t_Return.substring(0, 8)
        t_Return = this.$bizUtil.insertString(this.$bizUtil.insertString(t_Str, 6, '-'), 4, '-')
      }
      return t_Return
    },
    /******************************************************************************
     * Function명 : fn_BottomConfirm
     * 설명       : Confirm 팝업 호출
     * listeners: function 등록후, 팝업에서 emit시 실행됩니다.
     ******************************************************************************/
   fn_BottomConfirm (contents) {
     this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         title: '알림',
         content: contents,
         title_pos_btn: "예",
         title_neg_btn: "아니오"
       },
       listeners: {
         onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_AlertPop);
           if (this.lv_ConfirmAprlProc) {
             this.fn_ConfirmAprvProcResult(this.lv_ConfirmAprlProc)
           }
         },
         onPopupClose: () => {
           this.$bottomModal.close(this.lv_AlertPop);
           this.fn_ConfirmAprvProcResult(false)
         }
       }
     })
   },
    /******************************************************************************
      * Function명 : fn_SearchList
      * 설명       : 조회
      * 초기화 후 조회 진행 ( 조건 변경, 버튼 클릭)
    ******************************************************************************/
    fn_SearchList () {
        this.lv_gBndlCnsntAprv.slice(0)
        this.lv_gBndlCnsntAprv = []
        this.stndKeyList = [{stndKeyId: 'nextKey', stndKeyVal: ''}]
        this.stndKeyVal = '' // 더보기 최초조회
        if (Object.keys(this.lv_SearchData).length === 0) {
          this.fn_SelListReqBndlCnsnt('init')
        } else {
          this.fn_SelListReqBndlCnsnt(this.lv_SearchData)
        }
    },
    /******************************************************************************
     * Function명 : fn_BottomAlert
     * 설명       : 확인 팝업 호출
     * listeners: function 등록후, 팝업에서 emit시 실행됩니다.
     ******************************************************************************/
   fn_BottomAlert (contents) {
     this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         single: true,
         title: '알림',
         content: contents
       },
       listeners: {
         onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_AlertPop);
         },
         onPopupClose: () => {
           this.$bottomModal.close(this.lv_AlertPop);
         }
       }
     })
   },
      /******************************************************************************
      * Function명 : fn_SearchUserSrvcMngScCd
      * 설명       : 로그인 사용자의 영업채널구분코드 검색
      *              사용자의 영업채널구분코드가 FC(FC, SFP지점장), FS(금융서비스), GP(GFC지점장), SA(WM지점장) 이면 최초화면진입 시 데이터 조회
      *              AM(AFC), GA(GA)이면 대리점 선택을 위한 상세조회팝업 이동 필요
      *              2022-08-10 차민아 프로 요구사항
      *              기존 사랑On권한 기반(F110, A110, G110 ...)으로 설계가 되어 있었으나, 권한 이름만 존재하고 실제 운영계의 역할과 연계가 되어있지 않음을 확인(ex) AFC지점장의 역할권한이 F110, A110 등록)
      *              지점장의 역할이 중복으로 들어갈 수 있으므로 영업채널구분코드를 통하여 소속 채널을 구분하도록 수정.
      * 인자       :
      * Return     :
      ******************************************************************************/
   fn_SearchUserSrvcMngScCd () {
     let t_srvcMngScCd = this.lv_UserInfo.srvcMngScCd
     let t_indexOfSrvcMngScCd = 'FC,FS,GP,SA'.indexOf(t_srvcMngScCd)
     this.indexOfAMGA = 'AM,GA'.indexOf(t_srvcMngScCd)
     this.indexOfFS = 'FS'.indexOf(t_srvcMngScCd)
     console.log('fn_SearchUserSrvcMngScCd', this.indexOfAMGA, this.indexOfFS)
     if (this.lv_UserInfo.isBMngr === 'Y' && t_indexOfSrvcMngScCd !== -1) {
       this.lv_SearchParam.inqrStrAplDtm = moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'), // 기간 시작일
       this.lv_SearchParam.inqrEndAplDtm = this.$bizUtil.getDateToString(new Date(), '-'), // 기간 종료일
       this.fn_SelListReqBndlCnsnt('init')
       this.lv_IsRole = true
       this.lv_IsFirst = false
     } else {
       this.fn_Init()
       this.fn_RoleBottomAlert()
     }
     return this.lv_IsRole
   },
    /******************************************************************************
     * Function명 : fn_Open514Pop
     * 설명       : 상세조회조건 팝업 호출
     * listeners: function 등록후, 팝업에서 emit시 실행됩니다.
     ******************************************************************************/
      fn_Open514Pop () {
        console.log('this.lv_SearchData', Object.keys(this.lv_SearchData).length)
        this.srchPopupShowYn = true // 상세조회조건 활성화시 true
        if (Object.keys(this.lv_SearchData).length === 0) {
          this.lv_popup514 = this.$bottomModal.open(MSPAP514P, {
          properties: {
            title: "상세조회조건",
            pSrnId: 'MSPAP508M',
            userInfo: this.getStore('userInfo').getters.getUserInfo,
            pSearchData: this.lv_SearchData
          },
          listeners: {
            onPopupConfirm: (pData) => {
              console.log("onPopUpClose");
              this.$bottomModal.close(this.lv_popup514);
              Object.assign(this.lv_SearchData, pData)
              this.fn_SearchList()
              this.lv_IsFirst = false
              console.log('this.lv_SearchData', this.lv_SearchData)
              
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_popup514);
            }
          }
        }); 
        } else {
          this.lv_popup514 = this.$bottomModal.open(MSPAP514P, {
          properties: {
            title: "상세조회조건",
            pSrnId: 'MSPAP508M',
            userInfo: this.getStore('userInfo').getters.getUserInfo,
            pSearchData: this.lv_SearchData

          },
          listeners: {
            onPopupConfirm: (pData) => {
              console.log("onPopUpClose");
              this.$bottomModal.close(this.lv_popup514);

              Object.assign(this.lv_SearchData, pData)
              this.fn_SearchList()
              this.lv_IsFirst = false
              console.log('this.lv_SearchData', this.lv_SearchData)
              
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_popup514);
            }
          }
        });
        }
      },
      /******************************************************************************
      * Function명 : fn_Init
      * 설명       : 화면 초기화
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_Init () {
        this.lv_gBndlCnsntAprv.slice(0)
        this.lv_gBndlCnsntAprv = []
        this.stndKeyList = [{stndKeyId: 'next_key', stndKeyVal: ''}]
      },
      /******************************************************************************
      * Function명 : fn_BackBtnClicked
      * 설명       : 백 버튼 클릭이벤트(Device Back 포함)
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_BackBtnClicked () {
        // 상세조회조건 팝업을 호출한 적이 있으면 뒤로가기 대신 화면을 초기화 한다.
        if (this.srchPopupShowYn) {
          this.lv_SearchData = {}
          this.fn_SearchUserSrvcMngScCd()
          this.srchPopupShowYn = false
          this.lv_IsFirst = true
          this.lv_NodataFlag = 1
        } else {
          this.$router.go(-1)
        }
      },
    /******************************************************************************
    * Function명 : fn_RoleBottomAlert
    * 설명       : 단순알림 팝업 호출
    ******************************************************************************/
      fn_RoleBottomAlert () {
        this.lv_AlertPopup = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: '알림',
            content: '대리점을 먼저 선택해 주세요.'
          },
          listeners: {
            onPopupConfirm: () => {
              this.fn_Open514Pop()
              this.$bottomModal.close(this.lv_AlertPopup);
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_AlertPopup);
            }
          }
        },
        {properties: {preventTouchClose : false}}
        )},
  }
};
</script>
<style scoped>
</style>